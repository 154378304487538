import React from "react"
import styled from "styled-components"

const ButtonBlack = ({ name, price, link, handleScroll, bright }) => {
  return (
    <Container bright={bright}>
      <StyledLink
        href={link}
        target="_blank"
        onClick={handleScroll}
        bright={bright}
      >
        {name}{" "}
        {price ? (
          <>
            {/* <Price>
              <strike>5,000₽</strike>
            </Price> */}
             <Discounted><Price>{price}</Price>{' '}<strike>5,000 ₽</strike></Discounted>
          </>
        ) : null}
      </StyledLink>
    </Container>
  )
}

const Container = styled.div`
  display: inline-block;
  transform: skew(-7deg);
  &:before {
    height: 105%;
    top: -10.5%;
    transition: all 0.2s;
    background: ${props => (props.bright ? "#fff" : props.theme.red)};
    display: block;
    content: "";
    position: absolute;
    left: 4px;
    width: 100%;
    z-index: -4;
  }
`
const StyledLink = styled.a`
  position: relative;
  display: inline-block;
  background: ${props => (props.bright ? props.theme.red : props.theme.black)};
  color: ${props => (props.bright ? props.theme.black : "#fff")};
  // text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  border-bottom: none;
  line-height: 1;
  z-index: 10;
  padding: 16px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.1px;
`

const Price = styled.span`
  border-left: 1px solid #fff;
  display: inline-block;
  position: relative;
  font-weight: 400;
  padding-left: 15px;
  margin-left: 15px;
  font-size: 1.7rem;
  font-weight: bold;
  strike {
    padding: 0 10px 0 0;
  }
`
const Discounted = styled.span`
  display: inline-block;
  position: relative;
  font-weight: 400;
  font-size: 1.7rem;
`

export default ButtonBlack

import React from "react"
import styled from "styled-components"

const Teacher = () => (
  <Container>
    <Wrapper>
      <img
        src="https://res.cloudinary.com/dlmeqtsfq/image/upload/v1587554107/REACT001/main_selfie.jpg"
        alt="курс реакт"
      ></img>
      <Text>
        <h2>Василий Муравьев</h2>
        <p>
          Работаю <strong>frontend</strong> разработчиком в курпнейшем ритейлере
          России <strong>X5 Retail Group</strong>. В свободное время участвую в
          сторонних проектах в качестве <strong>full stack </strong>
          разработчика, где использую React, Node.js + Express и MongoDB.
        </p>
        <p>
          Занимаюсь созданием <strong>курсов</strong> в рамках моей платформы{" "}
          <a target="_blank" href="https://stackdev.ru">
            stackdev.ru
          </a>
          , где стараюсь сфокусироваться на практике и решении самых актуальных
          задач. Стремлюсь передать накопленный <strong>опыт</strong> и
          показать, что начать путь разработчика <strong>никогда</strong> не
          поздно!
        </p>
      </Text>
    </Wrapper>
  </Container>
)

export default Teacher

const Container = styled.div`
  background-color: ${props => props.theme.red};
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='20' viewBox='0 0 100 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.184 20c.357-.13.72-.264 1.088-.402l1.768-.661C33.64 15.347 39.647 14 50 14c10.271 0 15.362 1.222 24.629 4.928.955.383 1.869.74 2.75 1.072h6.225c-2.51-.73-5.139-1.691-8.233-2.928C65.888 13.278 60.562 12 50 12c-10.626 0-16.855 1.397-26.66 5.063l-1.767.662c-2.475.923-4.66 1.674-6.724 2.275h6.335zm0-20C13.258 2.892 8.077 4 0 4V2c5.744 0 9.951-.574 14.85-2h6.334zM77.38 0C85.239 2.966 90.502 4 100 4V2c-6.842 0-11.386-.542-16.396-2h-6.225zM0 14c8.44 0 13.718-1.21 22.272-4.402l1.768-.661C33.64 5.347 39.647 4 50 4c10.271 0 15.362 1.222 24.629 4.928C84.112 12.722 89.438 14 100 14v-2c-10.271 0-15.362-1.222-24.629-4.928C65.888 3.278 60.562 2 50 2 39.374 2 33.145 3.397 23.34 7.063l-1.767.662C13.223 10.84 8.163 12 0 12v2z' fill='%23ede9f3' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E");
  background-size: 100px;
  overflow-x: hidden;
  padding: 5rem 2rem;
  box-shadow: inset 0 -15px 0 -10px rgba(27, 27, 27, 0.1),
    inset 0 15px 0 -10px rgba(27, 27, 27, 0.1);
  margin-bottom: 56px;
`
const Wrapper = styled.div`
  max-width: 1240px;
  margin: 0 auto;
  background: #fff;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 500px) {
    display: block;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    flex: 1;
    padding-right: 20px;
    max-width: 225px;
    @media (max-width: 500px) {
      max-width: 100%;
      padding-right: 0;
    }
  }
`

const Text = styled.div`
  flex: 2;
  h2 {
    margin-top: 0;
  }
  p {
    line-height: 1.6;
  }
`

import React from 'react';
import styled from 'styled-components';

const Info = () => (
  <Container>
    <Wrapper>
      <Box>
        <h2>Чeму вы научитесь?</h2>
        <ul>
          <li>
            Создавать приложение на React.JS <strong>с нуля</strong>
          </li>
          <li>
            Cоздавать <strong>компоненты</strong> React, используя JSX синтаксис
          </li>
          <li>
            Использовать <strong>модули</strong> Javascript
          </li>
          <li>
            Использовать новые фичи Javascript <strong>ES6</strong> при
            написании кода
          </li>
          <li>
            Создавать и управлять <strong>сосотоянием</strong> компонентов
          </li>
          <li>
            Передавать данные <strong>между</strong> компонентами
          </li>
          <li>
            Использовать объект <strong>LocalStorage</strong> в браузерах
          </li>
          <li>
            Создавать маршрутизацию с помощью <strong>React Router 4</strong>
          </li>
          <li>
            Обрабатывать данные из <strong>форм</strong>
          </li>
          <li>
            Использовать <strong>хук</strong> useState
          </li>
          <li>
            Использовать возможности базы данных{' '}
            <strong>Google Firebase</strong>
          </li>
          <li>
            Создавать систему <strong>авторизации</strong>
          </li>

          <li>
            Развертывать приложение на <strong>хостинге</strong> (Netlify и Now)
          </li>
          <li>и другие...</li>
        </ul>
      </Box>
      <Box>
        <h2>Для Кого Этот Курс?</h2>
        <ul>
          <li>
            Этот курс подойдет всем, у кого уже есть <strong>начальные</strong>{' '}
            знания JavaScript (например, в рамках моего базового <a href="https://stackdev.ru/courses/javascript" target="_blank">курса JavaScript</a>). 
          </li>
          <li>
            <strong>Начинающие</strong> разработчики, которые хотят перейти от
            верстки к написанию фронтенда
          </li>
          <li>
            Разработчики, которые привыкли использовать jQuery и хотят начать
            использовать <strong>нативный</strong> Javascript
          </li>
          <li>
            <strong>Wordpress</strong> разработчики, которым интересно начать
            использовать React
          </li>
        </ul>
      </Box>
    </Wrapper>
  </Container>
);

export default Info;

const Container = styled.div`
  padding: 56px 0;
  position: relative;
  text-align: center;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0%, 100% calc(100% - 100px), 0 100%);
  background-color: #3583ea;
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='20' viewBox='0 0 100 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.184 20c.357-.13.72-.264 1.088-.402l1.768-.661C33.64 15.347 39.647 14 50 14c10.271 0 15.362 1.222 24.629 4.928.955.383 1.869.74 2.75 1.072h6.225c-2.51-.73-5.139-1.691-8.233-2.928C65.888 13.278 60.562 12 50 12c-10.626 0-16.855 1.397-26.66 5.063l-1.767.662c-2.475.923-4.66 1.674-6.724 2.275h6.335zm0-20C13.258 2.892 8.077 4 0 4V2c5.744 0 9.951-.574 14.85-2h6.334zM77.38 0C85.239 2.966 90.502 4 100 4V2c-6.842 0-11.386-.542-16.396-2h-6.225zM0 14c8.44 0 13.718-1.21 22.272-4.402l1.768-.661C33.64 5.347 39.647 4 50 4c10.271 0 15.362 1.222 24.629 4.928C84.112 12.722 89.438 14 100 14v-2c-10.271 0-15.362-1.222-24.629-4.928C65.888 3.278 60.562 2 50 2 39.374 2 33.145 3.397 23.34 7.063l-1.767.662C13.223 10.84 8.163 12 0 12v2z' fill='%23ede9f3' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E");
  background-size: 100px;
`;

const Wrapper = styled.div`
  max-width: 1240px;
  margin: 0 auto;
  position: relative;
  padding: 0 20px 100px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const Box = styled.div`
  background: #fff;
  flex: 1 1 48%;
  max-width: 48%;
  border-radius: 5px;
  padding: 15px 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  h2 {
    text-align: center;
    margin: 0;
    text-transform: uppercase;
    line-height: 2;
    border-bottom: 1px solid #f654ae;
    padding: 1rem;
  }
  ul {
    padding: 10px 0;
    margin: 0;
  }
  li {
    list-style: none;
    padding: 0 10px;
    text-align: left;
    line-height: 1.8;
  }
  li::before {
    content: '■';
    margin-right: 0.5rem;
    color: #f654ae;
  }
  @media (max-width: 900px) {
    max-width: 100%;
    flex: 1 1 100%;
    margin-bottom: 20px;
  }
`;

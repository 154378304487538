import React from 'react';
import styled from 'styled-components';

const Comments = () => (
  <Container>
    <Wrapper>
      <Box>
        <h2>Некоторые отзывы о курсе (Youtube)</h2>
        <ul>
          <li>
            <img alt='отзыв о курсе react js' src='https://res.cloudinary.com/dlmeqtsfq/image/upload/v1619193109/REACT001/Comments/comment-2.jpg'/>
          </li>
          <li>
            <img alt='курс react отзыв' src='https://res.cloudinary.com/dlmeqtsfq/image/upload/v1619193109/REACT001/Comments/comment-3.jpg'/>
          </li>
          <li>
            <img alt='курсе react js отзыв' src='https://res.cloudinary.com/dlmeqtsfq/image/upload/v1619193108/REACT001/Comments/comment-4.jpg'/>
          </li>
          <li>
            <img alt='курс react001 отзывы' src='https://res.cloudinary.com/dlmeqtsfq/image/upload/v1619193108/REACT001/Comments/comment-1.jpg'/>
          </li>
          
        </ul>
      </Box>
   
    </Wrapper>
  </Container>
);

export default Comments;

const Container = styled.div`
   background: rgba(247, 247, 247, 0.5);
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.21);
    padding: 56px 20px;
`;

const Wrapper = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: space-between;
  @media (max-width: 900px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const Box = styled.div`
  margin: 0 auto;
  background: #fff;
  flex: 1 1 48%;
  max-width: 48%;
  border-radius: 5px;
  padding: 15px 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  h2 {
    text-align: center;
    margin: 0;
    text-transform: uppercase;
    line-height: 2;
    border-bottom: 1px solid #f654ae;
    padding: 1rem;
  }
  ul {
    padding: 10px 0;
    margin: 0;
  }
  li {
    display: block;
    list-style: none;
    padding: 0 10px;
    text-align: left;
    line-height: 1.8;
    img {
      max-width: 580px;
      height: 100%;
    }
  }

  @media (max-width: 900px) {
    max-width: 100%;
    flex: 1 1 100%;
  }
`;

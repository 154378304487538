import React from "react"
import styled from "styled-components"
import courseVideos from "../../../course-videos"
import Video from "../../general/Video/index"
import ButtonBlack from "../../general/ButtonBlack"
// import ImageCourse from "../../imageCourse"
import Teacher from "../Teacher"
import VideoIntro from '../VideoIntro'

const Courses = ({ startScrolling }) => {
  const handleScroll = e => {
    e.preventDefault()
    startScrolling()
  }
  return (
    <Container>
      <WelcomeContainer>
        <WelcomeWrap>
          <VideoIntro />
          {/* <Img_Wrap
            href="https://hot-burgers-public-demo-noauth.now.sh/"
            target="_blank"
          >
            <Picture>
              <ImageCourse />
            </Picture>
          </Img_Wrap> */}
          <Text>
            <h2>React.JS + Google Firebase!</h2>
            <p>
              В рамках курса по изучению библиотеки React JS, мы создадим{" "}
              <strong>приложение</strong> для заказа бургеров в сети ресторанов
              Hot Burgers! С помощью приложения можно будет в{" "}
              <strong>реальном времени</strong> управлять стоимостью и
              ассортиментом меню.
            </p>
            <p>
              Приложение будет включать компоненты <strong>меню</strong>,{" "}
              <strong>форму заказа</strong> и{" "}
              <strong>панель администратора</strong>. Наши данные будут
              храниться в базе данных <strong>Google Firebase</strong> и
              обновляться в режиме реального времени!{" "}
              <strong>Система авторизации</strong> позволит регулировать доступ
              к системе управления меню.
            </p>
            <p>
              Ознакомиться с демо версией приложения можно{" "}
              <a
                href="https://hot-burgers-public-demo-noauth.now.sh/"
                target="_blank"
              >
                здесь
              </a>
              .
            </p>
            <ButtonBlack
              name="Все Видео Курса"
              price={false}
              link="/"
              handleScroll={handleScroll}
            />
          </Text>
        </WelcomeWrap>
      </WelcomeContainer>
      <Teacher />
      <CoursesContainer>
        <CoursesHeaderWrap>
          <h2>
            Видео Уроки в <HD>HD</HD> Качестве
          </h2>
          <p>
            Курс <strong>React 001</strong> состоит из <strong>29 </strong>
            видео-уроков, записанных в <strong>HD</strong> качестве. Просмотр курсов
            доступен на <a target="_blank" href="https://www.youtube.com/watch?v=AcLeOeUbFd4&list=PLtL3lrXPn2rUiIj46eozwWn9P2x8RoOiR">Youtube</a> канале и в <a target="_blank" href="https://stackdev.ru">личном кабинете</a> пользователя. 
            <strong> Ссылка</strong> на исходники доступна после регистрации в <strong>личном кабинете</strong>. Все
            возникающие вопросы можно обсуждать в моем <strong>телеграм</strong> канале (ссыла доступна в личном кабинете).
            <br></br><strong>2 бонусных видео</strong> о размещении проекта на <strong>хостинге</strong> доступны в личном кабинете.
          </p>
        </CoursesHeaderWrap>
        <Videos>
          {courseVideos.map(data => {
            return <Video key={data.number} data={data} />
          })}
        </Videos>
      </CoursesContainer>
    </Container>
  )
}

const HD = styled.span`
  font-family: "settle_down";
  font-size: 10rem;
  line-height: 1px;
  vertical-align: middle;
  font-weight: 500;
  @media (max-width: 600px) {
    font-size: 8rem;
  }
`

const Container = styled.div`
  margin-top: 90px;
`

const CoursesContainer = styled.div`
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 2rem;
  text-align: center;
  h2 {
    font-weight: 700;
    margin-bottom: 36px;
    font-size: 42px;
    text-align: center;
    @media (max-width: 900px) {
      margin-bottom: 2rem;
      font-size: 3rem;
    }
  }
`

const WelcomeContainer = styled.div`
  max-width: 1200px;
  margin: 70px auto 56px;
`
const CoursesHeaderWrap = styled.div`
  max-width: 900px;
  margin: 0 auto;
`

const WelcomeWrap = styled.div`
  background: #fff;
  margin-top: -150px;
  z-index: 2;
  position: relative;
  padding: 20px;
  box-shadow: 0 0 0px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  @media (max-width: 900px) {
    flex-direction: column;
    /* align-items: center; */
    gap: 2rem;
  }
`

const Picture = styled.picture`
  img {
    width: 100%;
    display: block;
    border-radius: 2px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  }
`
const Img_Wrap = styled.a`
  margin: 0 3rem 0 0;
  padding: 0;
  border: 0;
  cursor: pointer;
  flex: 1;
  @media (max-width: 900px) {
    margin: 0;
  }
`
const Text = styled.div`
  flex: 1;
  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h2 {
    line-height: 1;
    font-family: "settle_down";
    font-size: 4rem;
    margin-top: -10px;
    @media (max-width: 900px) {
      text-align: center;
      margin-top: 1.5rem;
    }
  }
  p {
    line-height: 1.3;
    margin: 0 auto 2rem auto;
  }
`
const Videos = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 500px) {
    margin-left: 2rem;
  }
`

export default Courses

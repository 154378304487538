import React, { useEffect } from 'react';
import styled from "styled-components"
import smoothscroll from 'smoothscroll-polyfill';
// import { FaHamburger } from 'react-icons/fa'
import ImageMain from "../../imageMain"
import ButtonBlack from "../../general/ButtonBlack"

const Intro = ({ startScrolling }) => {
  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  const handleScroll = e => {
    e.preventDefault()
    startScrolling()
  }
  return (
    <Container>
      <ImageMain />
      <Wraper>
        <Header>
          <h1>
            <CourseTag>
              Курс React JS<span> →</span>
            </CourseTag>
            <CourseTitle>REACT 001</CourseTitle>
          </h1>
          <SubHeader>
            {/* Пишем <span>web-приложение</span> на React с нуля!{" "} */}
            Вся теория для начинающих за <span>6</span> часов!
            <br></br>Добавь крутой проект в <span>портфолио</span>!
            <br></br>Улучши знания <span>JavaScript</span>.
          </SubHeader>
          <ButtonBlack
            name="Начать Обучение"
            price={false}
            link="/"
            handleScroll={handleScroll}
            bright={true}
          />
        </Header>
      </Wraper>
    </Container>
  )
}
const Container = styled.div`
  position: relative;
  color: #fff;
  clip-path: polygon(0 0, 100% 0%, 100% calc(100% - 100px), 0 100%);
  padding-bottom: 130px;
  padding-top: 50px;
  overflow: hidden;
  margin-bottom: -100px;
  border-top: ${props => `10px solid ${props.theme.red}`};
  /* background-image: url("https://res.cloudinary.com/dlmeqtsfq/image/upload/v1587399441/REACT001/main_1920_800.jpg");
  background-size: cover; */
  @media (max-width: 1000px) {
    clip-path: none;
  }
  @media (max-width: 490px) {
    padding-top: 0;
    padding-bottom: 60px;
  }
`

const Wraper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  h1 {
    position: relative;
    line-height: 1;
    padding: 0;
    margin: 0;
    color: #fff;
    text-align: center;
  }
`

const CourseTag = styled.span`
  display: block;
  max-width: 160px;
  background: rgba(255, 255, 255, 0.33);
  padding: 3px 0;
  border-radius: 0px;
  color: #1b1b1b;
  font-size: 1.8rem;
  span {
    font-family: "settle_down";
  }
  @media (max-width: 490px) {
    font-size: 1.5rem;
  }
`
const CourseTitle = styled.span`
  font-family: "settle_down";
  font-size: 23rem;
  letter-spacing: 1px;
  @media (max-width: 800px) {
    font-size: 22vw;
  }
`

const SubHeader = styled.p`
  font-size: 2.2rem;
  font-weight: 500;
  margin: 0;
  text-align: center;
  line-height: 1.4;
  letter-spacing: 1px;
  margin-bottom: 3rem;
  span {
    color: ${props => props.theme.red};
  }
  svg {
    color: ${props => props.theme.red};
  }
  @media (max-width: 800px) {
    font-size: 2rem;
  }
  @media (max-width: 600px) {
    font-size: 1.8rem;
  }
`

const Header = styled.div`
  background: rgb(0, 0, 0, 0.7);
  padding: 4rem 2rem 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 490px) {
    padding-bottom: 6rem;
  }
`

export default Intro

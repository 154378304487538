import React from 'react';
import styled from 'styled-components';

const Footer = () => (
  <StyledFooter>
    <Wrap>
      <div></div>
      <CopyRight>
        © {new Date().getFullYear()}
        {` `}
        <a target="_blank" href="https://stackdev.ru">
          StackDev.ru
        </a>
      </CopyRight>
    </Wrap>
  </StyledFooter>
);

const StyledFooter = styled.footer`
  max-width: 100%;
  font-weight: 300;
  padding: 0 0 5rem;
`;
const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin: 0;
  }
`;
const CopyRight = styled.div`
  margin: 0 auto;
  @media only screen and (max-width: 390px) {
    margin-top: 5px;
  }
`;

export default Footer;

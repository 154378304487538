import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import ButtonBlack from "../../general/ButtonBlack"

const Pricing = ({ handleChange }) => {
  const pricingRef = useRef(null)

  useEffect(() => {
    handleChange(pricingRef.current)
  })

  return (
    <Container ref={pricingRef}>
      <h2 className="header">Доступ к Учебным Материалам</h2>

      <Wrapper>
        <div className="package first">
          <Info>
            <h2>Полный курс по React JS</h2>
            <img
              src="https://res.cloudinary.com/dlmeqtsfq/image/upload/v1652019145/REACT001/React001-Card-2022_640_360.jpg"
              alt="курс react"
            ></img>
            <InfoList>
              <li>
                <strong>27 Видео</strong> с пошаговыми инструкциями в{" "}
                <strong>HD</strong> качестве
              </li>
              <li>
                <strong>+ 2 Бонусных видео</strong> по размещению готового
                проекта на популярных хостингах <strong>React</strong> приложений: Netlify и Vercel.
              </li>
              {/* <li>
                <strong>Пожизненная</strong> возможность{" "}
                <strong>бесплатно</strong> получать обновления и дополнения к
                курсу
              </li> */}
              <li>
                Весь <strong>исходный код</strong> для нового и готового
                приложения (файлы и скрипты)
              </li>
              <li>
                Доступ в <strong>закрытый</strong> Telegram канал, где можно
                задать вопрос и обсудить решения
              </li>
            </InfoList>
          </Info>
          <Buy>
            <ButtonBlack
              name="Купить Курс React 001"
              link="https://stackdev.ru/signup-paid?code=002"
              price='2,200 ₽'
            />
          </Buy>    
        </div>

        {/* <div className="package second">
          <Info>
            <h2>Доступ ко всем курсам Stackdev</h2>
            <img
              src="https://res.cloudinary.com/dlmeqtsfq/image/upload/v1678718579/2023-Stackdev/subscription-thumbs/subscribe-card-2.jpg"
              alt="подписка stackdev"
            ></img>
            <InfoList>
              <li>
                Весь контент Stackdev в <strong>HD</strong> качестве
              </li>
              <li>
                Весь <strong>исходный код</strong> и учебные материалы
              </li>
              <li>
                Доступ в Telegram каналы <strong>всех курсов</strong>, где можно задать вопрос и обсудить решения
              </li>
              <li>
                Доступ ко всем <strong>обновлениям</strong> и дополнениям материала на Stackdev
              </li>
            </InfoList>
          </Info>
          <Buy>
            <ButtonBlack
              name="Оформить Подписку"
              link="https://stackdev.ru/pricing"
              price='от 750 ₽ / месяц'
            />
          </Buy>
        </div> */}
      </Wrapper>
    </Container>
  )
}

export default Pricing

const Container = styled.div`
  background: rgba(247, 247, 247, 0.5);
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.21);
  padding: 56px 0;

  .header {
    font-weight: 700;
    margin-bottom: 36px;
    font-size: 42px;
    text-align: center;
    @media (max-width: 900px) {
      margin-bottom: 2rem;
      font-size: 3rem;
    }
  }
`
const Wrapper = styled.div`
  display: flex;
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px;
  justify-content: center;

  .package {
    width: 49%;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    padding-bottom: 30px;
    position: relative;
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.03);
  }

  .first {
    margin-right: 1.5%;
  }
  .second {
    margin-left: 1.5%;
  }

  @media (max-width: 980px) {
      flex-wrap: wrap;

      .first {
        margin-right: 0;
        margin-bottom: 22px;
      }
      .second {
        margin-left: 0;
      }

      .package {
        width: 100%;
      }
    }
`;

const Info = styled.div`
  width: 100%;
  background: #fff;
  h2 {
    padding: 2rem;
    margin: 0;
  }
  img {
    width: 100%;
    object-fit: cover;
  }
`
const Buy = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 42px 22px 0;
`
const InfoList = styled.ul`
  margin: 0;
  text-align: left;
  list-style: square;
  list-style: none;
  padding: 0;
  li {
    line-height: 2rem;
    padding: 5px 10px;
    border-bottom: 1px solid #e0e0e0;
    text-align: left;
    list-style: none;
    line-height: 2;
  }
  li::before {
    content: "■";
    margin-right: 0.5rem;
    color: ${props => props.theme.red};
  }
`



import React from 'react';
import styled from 'styled-components';

const Video = (props) => {
  const { name, image, duration, number } = props.data;
  return (
    <Container>
      <img src={image} alt={name}></img>
      <Title>{name}</Title>
      <Duration>{duration}</Duration>
      <Order>#{number}</Order>
    </Container>
  );
};
const Container = styled.li`
  list-style: none;
  flex: 1 1 15%;
  max-width: 15%;
  text-align: center;
  margin-bottom: 20px;
  position: relative;
  img {
    width: 100%;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.39);
    border: 2px solid #fff;
    @media (max-width: 500px) {
      display: none;
    }
  }
  @media (max-width: 1000px) {
    max-width: 18%;
    flex-basis: 18%;
  }
  @media (max-width: 600px) {
    max-width: 23%;
    flex-basis: 23%;
  }
  @media (max-width: 500px) {
    list-style: decimal;
    max-width: 100%;
    flex-basis: 100%;
    text-align: left;
  }
`;

const Title = styled.h4`
  margin: 0;
  font-weight: normal;
  text-align: left;
  font-size: 1.3rem;
  @media (max-width: 500px) {
    display: inline;
    text-transform: uppercase;
  }
`;
const Duration = styled.span`
  position: absolute;
  top: 3px;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  color: ${(props) => props.theme.red};
  font-size: 12px;
  padding: 0px 5px;
  @media (max-width: 500px) {
    position: relative;
    top: 0;
    margin-left: 3px;
    color: #fff;
    background: #f654ae;
  }
`;
const Order = styled.span`
  position: absolute;
  top: 3px;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  color: ${(props) => props.theme.red};
  font-size: 12px;
  padding: 0px 5px;
  @media (max-width: 500px) {
    display: none;
  }
`;

export default Video;

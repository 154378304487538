import React from "react"
import styled from "styled-components"
import ReactPlayer from 'react-player';


const VideoIntro = () => {
const playIt = (e) => {
    console.log(e.target.play)
}
  return (
    <Container onClick={playIt}>
          {/* <video poster="https://learnnode.com/images/NODE/poster.jpg" src="https://youtu.be/_G9UB_3M4UQ" width="640">
          </video> */}
           <ReactPlayer
                className='react-player'
                url="https://www.youtube.com/watch?v=I0nBgJWdfS4"
                controls
                width='100%'
                height='100%'
                //  playing
        />
    </Container>
  )
}


const Container = styled.div`
    padding-right: 20px;
    flex: 1;
    text-align: left;
    @media (max-width: 900px) {
        position: relative;
        padding-top: 56.25%;
        padding-right: 0;
    }
    .react-player {
        margin-right: 3rem;
        border-radius: 2px;
        box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
            @media (max-width: 900px) {
                position: absolute;
                top: 0;
                left: 0;
            }
    }
`


export default VideoIntro

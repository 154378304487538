const courseVideos = [
  {
    image:
      'https://res.cloudinary.com/dlmeqtsfq/image/upload/v1587023364/REACT001/landing-grid/01-video.jpg',
    name: 'Настраиваем Инструменты',
    duration: '15:24',
    number: 1,
  },
  {
    image:
      'https://res.cloudinary.com/dlmeqtsfq/image/upload/v1587023364/REACT001/landing-grid/02-video.jpg',
    name: 'Компоненты React',
    duration: '06:49',
    number: 2,
  },
  {
    image:
      'https://res.cloudinary.com/dlmeqtsfq/image/upload/v1587023364/REACT001/landing-grid/03-video.jpg',
    name: 'Создаем первый компонент Landing',
    duration: '09:46',
    number: 3,
  },
  {
    image:
      'https://res.cloudinary.com/dlmeqtsfq/image/upload/v1587023364/REACT001/landing-grid/04-video.jpg',
    name: 'JSX в React',
    duration: '12:31',
    number: 4,
  },
  {
    image:
      'https://res.cloudinary.com/dlmeqtsfq/image/upload/v1587023364/REACT001/landing-grid/05-video.jpg',
    name: 'Добавляем CSS в React',
    duration: '02:56',
    number: 5,
  },
  {
    image:
      'https://res.cloudinary.com/dlmeqtsfq/image/upload/v1587023364/REACT001/landing-grid/06-video.jpg',
    name: 'Объект State, обработчики событий и значение this',
    duration: '07:54',
    number: 6,
  },
  {
    image:
      'https://res.cloudinary.com/dlmeqtsfq/image/upload/v1587023364/REACT001/landing-grid/07-video.jpg',
    name: 'Пишем логику обновления объекта State',
    duration: '09:28',
    number: 7,
  },
  {
    image:
      'https://res.cloudinary.com/dlmeqtsfq/image/upload/v1587023364/REACT001/landing-grid/08-video.jpg',
    name: 'Головной компонент App и структура приложения',
    duration: '12:15',
    number: 8,
  },
  {
    image:
      'https://res.cloudinary.com/dlmeqtsfq/image/upload/v1587023364/REACT001/landing-grid/09-video.jpg',
    name: 'Объект Props в React',
    duration: '09:03',
    number: 9,
  },
  {
    image:
      'https://res.cloudinary.com/dlmeqtsfq/image/upload/v1587023364/REACT001/landing-grid/10-video.jpg',
    name: 'Функциональные компоненты React',
    duration: '05:40',
    number: 10,
  },
  {
    image:
      'https://res.cloudinary.com/dlmeqtsfq/image/upload/v1587023364/REACT001/landing-grid/11-video.jpg',
    name: 'Настраиваем React Router',
    duration: '09:23',
    number: 11,
  },
  {
    image:
      'https://res.cloudinary.com/dlmeqtsfq/image/upload/v1587023364/REACT001/landing-grid/12-video.jpg',
    name: 'Push State в компоненте Landing',
    duration: '06:34',
    number: 12,
  },
  {
    image:
      'https://res.cloudinary.com/dlmeqtsfq/image/upload/v1587023364/REACT001/landing-grid/13-video.jpg',
    name: 'Объект State в компоненте App',
    duration: '29:20',
    number: 13,
  },
  {
    image:
      'https://res.cloudinary.com/dlmeqtsfq/image/upload/v1587023364/REACT001/landing-grid/14-video.jpg',
    name: 'Загружаем бургеры в меню',
    duration: '07:55',
    number: 14,
  },
  {
    image:
      'https://res.cloudinary.com/dlmeqtsfq/image/upload/v1587023364/REACT001/landing-grid/15-video.jpg',
    name: 'Отображаем бургеры из объекта State',
    duration: '14:38',
    number: 15,
  },
  {
    image:
      'https://res.cloudinary.com/dlmeqtsfq/image/upload/v1587023364/REACT001/landing-grid/16-video.jpg',
    name: 'Объект State в компоненте Order',
    duration: '17:10',
    number: 16,
  },
  {
    image:
      'https://res.cloudinary.com/dlmeqtsfq/image/upload/v1587023364/REACT001/landing-grid/17-video.jpg',
    name: 'Отображаем компонент Order',
    duration: '22:56',
    number: 17,
  },
  {
    image:
      'https://res.cloudinary.com/dlmeqtsfq/image/upload/v1587023364/REACT001/landing-grid/18-video.jpg',
    name: 'Пишем компонент Shipment',
    duration: '10:55',
    number: 18,
  },
  {
    image:
      'https://res.cloudinary.com/dlmeqtsfq/image/upload/v1587023364/REACT001/landing-grid/19-video.jpg',
    name: 'Интеграция с Google Firebase',
    duration: '15:49',
    number: 19,
  },
  {
    image:
      'https://res.cloudinary.com/dlmeqtsfq/image/upload/v1587023364/REACT001/landing-grid/20-video.jpg',
    name: 'Cохраняем данные о заказе в Local Storage',
    duration: '12:21',
    number: 20,
  },
  {
    image:
      'https://res.cloudinary.com/dlmeqtsfq/image/upload/v1587023364/REACT001/landing-grid/21-video.jpg',
    name: 'Редактирование свойств бургера',
    duration: '22:00',
    number: 21,
  },
  {
    image:
      'https://res.cloudinary.com/dlmeqtsfq/image/upload/v1587023364/REACT001/landing-grid/22-video.jpg',
    name: 'Удаление бургера из меню',
    duration: '08:11',
    number: 22,
  },
  {
    image:
      'https://res.cloudinary.com/dlmeqtsfq/image/upload/v1587023364/REACT001/landing-grid/23-video.jpg',
    name: 'Создание анимации',
    duration: '27:36',
    number: 23,
  },
  {
    image:
      'https://res.cloudinary.com/dlmeqtsfq/image/upload/v1587023364/REACT001/landing-grid/24-video.jpg',
    name: 'Прописываем Prop Types',
    duration: '18:10',
    number: 24,
  },
  {
    image:
      'https://res.cloudinary.com/dlmeqtsfq/image/upload/v1587023364/REACT001/landing-grid/25-video.jpg',
    name: 'React Хук useState',
    duration: '11:11',
    number: 25,
  },
  {
    image:
      'https://res.cloudinary.com/dlmeqtsfq/image/upload/v1587023364/REACT001/landing-grid/26-video.jpg',
    name: 'Авторизация с Google Firebase',
    duration: '32:35',
    number: 26,
  },
  {
    image:
      'https://res.cloudinary.com/dlmeqtsfq/image/upload/v1587023364/REACT001/landing-grid/27-video.jpg',
    name: 'Сборка готового приложения',
    duration: '03:24',
    number: 27,
  },
  {
    image:
      'https://res.cloudinary.com/dlmeqtsfq/image/upload/v1587023364/REACT001/landing-grid/28-video.jpg',
    name: 'Размещение на хостинге Now',
    duration: '06:01',
    number: 28,
  },
  {
    image:
      'https://res.cloudinary.com/dlmeqtsfq/image/upload/v1587023364/REACT001/landing-grid/29-video.jpg',
    name: 'Размещение на хостинге Netlify',
    duration: '06:41',
    number: 29,
  },
];

export default courseVideos;

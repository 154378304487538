import React from "react"
import styled from "styled-components"

const WhatIsReact = () => (
  <Container>
    <Wrapper>
      <h2>Основные Темы Курса</h2>
      <ul>
        <li><strong>Компоненты.</strong> Говоря о React, нельзя избежать обсуждения концепции компонентов. Компоненты в React — это основной строительный блок для создания фрагментов HTML-кода, подходящих для повторного использования.
        </li>
        <li><strong>JSX.</strong> Это — синтаксическое расширение JavaScript, которое позволяет создавать компоненты, используя возможности HTML и JavaScript.</li>
        <li><strong>Стилизация компонентов.</strong> Стилизация позволяет придать компонентам привлекательный внешний вид (используем препроцессор Stylus).</li>
        <li><strong>Свойства и обмен данными в приложении.</strong> Свойства используются для передачи данных между компонентами.</li>
        <li><strong>Состояние.</strong> Механизмы состояния компонентов используются для хранения данных в приложении и для управления ими.</li>
        <li><strong>Обработка событий.</strong> События позволяют наладить интерактивные взаимоотношения с пользователями приложений.</li>
        <li><strong>Методы жизненного цикла компонентов.</strong> Эти методы позволяют разработчику влиять на различные события, происходящие с компонентами.</li>
        <li><strong>Взаимодействие с внешними API.</strong> Будем загружать данные из внешних API с использованием протокола HTTP.</li>
        <li><strong>Использование форм в React.</strong> Создадим несколько форм для добавления и редактирования бургеров в меню.</li>
        <li><strong>React Хуки (hooks).</strong> Данное нововведение появилось в React версии 16.8. Хуки позволяют использовать состояние и другие возможности React JS без написания классов. В данном курсе мы мы изучим, как работает хук useState.</li>
      </ul>
    </Wrapper>

    <Wrapper>
      <h2>Зачем Использовать React?</h2>
      <p>
      Зачем использовать React, если можно разработать веб-проект на чистом JavaScript? 
      Если вы интересуетесь веб-разработкой, то, возможно, слышали о том, что React позволяет создавать очень <strong>быстрые</strong> приложения, производительность которых превышает то, что достижимо с использованием лишь JavaScript. 
      Это достигается за счёт использования в React технологии, называемой <strong>Virtual DOM</strong>. 
      </p>
  
    </Wrapper>

    <Wrapper>
      <h2>Что Такое React?</h2>
      <p>
        <strong>ReactJS</strong> - библиотека для создания современных
        пользовательских интерфейсов (UI), которая изменила наш подход к
        разработке <strong>фронтенда</strong>.
      </p>
      <p>
        При пректировании UI, в React используется <strong>компонентный</strong>{" "}
        подход. Это означает, что любое сложное решение можно разбить на
        несколько изолированных частей кода (компонентов). В дальнейшем их можно{" "}
        <strong>повторно </strong>
        использовать в других частях приложения.
      </p>
      <p>
        Концепция <strong>Виртуальный DOM</strong> (Virtual DOM) позволяет
        обновлять только те части приложения, которые изменились. Это делает
        приложения, созданные на React, более <strong>быстрыми</strong> и
        производительными.
      </p>

      <p>
        Сегодня <strong>тысячи</strong> компаний в России и по всему миру
        используют React для разработки своих пользовательских интерфейсов.
        Среди них можно упомянуть: Facebook, Airbnb, Netflix, Instagram, Yandex,
        Тинькофф, Сбербанк, Авито и многие другие.
      </p>
      <p>
        Одной из основных причин популярности React можно считать тот факт, что разработкой и поддержкой этой библиотеки занимается <strong>Facebook</strong>. 
        Это, по меньшей мере, означает, что React на <strong>постоянной</strong> основе, занимаются лучшие программисты. 
        На популярность React также влияет тот факт, что этот проект является <strong>опенсорсным</strong>. Код проекта опубликован на GitHub, а это значит, что вклад в проект делают множество <strong>сторонних</strong> разработчиков. 
        Всё это позволяет говорить о том, что React, в обозримом будущем, будет жить и <strong>развиваться</strong>.
      </p>
      <p>
        Говоря о React, и, в частности, о том, почему эту библиотеку стоит <strong>изучать</strong>, нельзя не вспомнить об огромном <strong>рынке труда</strong>, связанном с этой библиотекой. 
        В наши дни React-специалисты пользуются <strong>устойчивым</strong> спросом. Если вы изучаете React с целью найти работу в сфере фронтенд-разработки — это означает, что вы на правильном пути.
      </p>
    </Wrapper>
  </Container>
)

export default WhatIsReact

const Container = styled.div`
  overflow-x: hidden;
  padding: 56px 20px;
`
const Wrapper = styled.div`
  max-width: 1200px;
  box-shadow: 0 0 0px 4px rgba(0, 0, 0, 0.05);
  margin: 0 auto;
  background: #fff;
  padding: 20px;
  margin-bottom: 40px;
  ul {
    margin: 0;
    padding: 10px 0;
  }
  ul li {
    list-style: none;
  }
  ul li::before {
    content: '■';
    margin-right: 0.5rem;
    color: #f654ae;
    font-size: 20px;
  }
`
